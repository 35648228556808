body {
     /* Light grey background */
    font-family: Arial, sans-serif; /* Use a sans-serif font similar to the website */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
  }
  
  .navbar-brand {
    color: #ffffff !important; /* Navbar brand text color */
    font-weight: bold; /* Make the brand font bold */
  }
  
  .navbar-nav .nav-link {
    color: #ffffff !important; /* Navbar link text color */
  }
  
  .navbar-nav .nav-link:hover {
    color: #0066CC !important; /* Change link color on hover to secondary blue */
  }
  
  .box {
    border: 1px solid #dfdfdf;
    background-color: #ffffff; /* White background for content box */
    border-radius: 8px; /* Add some border radius to soften edges */
    padding: 20px; /* Add padding inside the box */
    margin-top: 20px; /* Add some space between navbar and content */
  }
  
  .g-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    background-color: #007bff; /* Button background color */
    border: none;
    border-radius: 5px;
    color: #ffffff; /* Button text color */
    cursor: pointer;
  }
  

  